import { Outlet } from 'react-router-dom';
import React from 'react';
import Maps from './components/Maps';
import MarkerIcon from '@mui/icons-material/LocationOn';
import { useFetch } from '../../hooks/useFetch';
import { Marker } from '@react-google-maps/api';
import LoadingContainer from '../../components/LoadingContent';

export function Tracker() {
    const { data, error, mutate, isFetching } = useFetch('tracker');

    if (isFetching) return <LoadingContainer label="Carregando dados" />;

    return <Maps points={data} />;
}
