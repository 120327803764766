import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingContainer from './LoadingContent';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress, Dialog, Divider, IconButton, Tooltip } from '@mui/material';
import SupportDialog from './SuportDialog';
import { useApp } from '../contexts/AppContext';
import OrderStatus from './OrderStatusComponent';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

export default function SupportFeed({ data, mutate, isFetching }) {
    const { socket } = useAuth();
    const [hasRootOrAdminTeam, setHasRootOrAdminTeam] = useState(false);
    const { newChats, setNewChats, openedChatService, setOpenedChatService } = useApp();
    const navigation = useNavigate();

    useEffect(() => {
        const userStringfy = sessionStorage.getItem('user');
        const userData = JSON.parse(userStringfy);
        const userTeams = userData.UserTeams;
        if (userTeams) {
            if (userTeams.some(team => team.team === 'root')) {
                setHasRootOrAdminTeam(true);
            } else if (userTeams.some(team => team.team === 'admin')) {
                setHasRootOrAdminTeam(true);
            }
        } else {
            setHasRootOrAdminTeam(false);
        }
    }, []);

    if (isFetching) return <LoadingContainer />;

    return (
        <>
            {!Boolean(data.length) && (
                <Box
                    key="none_support"
                    sx={{
                        padding: '1rem',
                        height: '100%',
                        background: '#F1F5F9',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}>
                    <Typography sx={{ fontSize: '1.1rem', fontWeight: '700', color: '#64748B' }}>Nenhum serviço em andamento</Typography>
                </Box>
            )}
            {data?.map(item => (
                <Box
                    key={`feed_${item.id}`}
                    sx={{
                        padding: '0.8rem',
                        marginBottom: '1rem',
                        background: `${newChats.includes(item.id) ? '#E0F2F1' : '#FFFFFF'}`,
                        borderRadius: '8px',
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                        position: 'relative',
                    }}>
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: '600', marginBottom: '0.5rem', color: '#09136f' }}>
                        {item.Order.Professional.User.name}
                    </Typography>

                    <Typography sx={{ fontSize: '0.8rem', fontWeight: '500' }}>
                        Ordem:{' '}
                        <strong
                            onClick={() => {
                                navigation(`/orders/${item.Order.id}`);
                            }}
                            style={{ color: '#007BFF', cursor: 'pointer', textDecoration: 'underline' }}>
                            #{item.Order.id}
                        </strong>
                    </Typography>

                    <Typography sx={{ fontSize: '0.8rem', fontWeight: '500', marginTop: '0.3rem' }}>
                        Cliente: <strong>{item.Order.Customer.customer_name}</strong>
                    </Typography>
                    {hasRootOrAdminTeam && (
                        <OrderStatus
                            orderId={item.Order.id}
                            currentStatus={item.Order.status}
                            onStatusUpdated={newStatus => {
                                // Atualize o estado local ou faça um mutate para refetch
                                mutate();
                            }}
                        />
                    )}

                    {Boolean(item.Conversations.length) && (
                        <Typography sx={{ fontSize: '0.9rem', fontWeight: '400', color: '#4B5563' }}>{item.Conversations[0].message}</Typography>
                    )}

                    <Box sx={{ position: 'absolute', top: '2.2rem', right: '1.5rem' }}>
                        <Tooltip title="Enviar mensagem">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setOpenedChatService({ ...item });
                                    newChats.splice(newChats.indexOf(item.service_id), 1);
                                    setNewChats([...new Set(newChats)]);
                                }}>
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            ))}
            {Boolean(openedChatService) && (
                <SupportDialog service={openedChatService} open={Boolean(openedChatService)} handleClose={() => setOpenedChatService(null)} />
            )}
        </>
    );
}
