import React, { useState } from 'react';
import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

//      onArchive: () => void;
//      onDelete: () => void;
//      onEdit: () => void;

const IconButtons = ({ onArchive, onDelete, onEdit }) => {
    const [openModal, setOpenModal] = useState(false);
    const [actionType, setActionType] = useState();

    const handleOpenModal = type => {
        setActionType(type);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setActionType(null);
    };

    const handleConfirmAction = () => {
        if (actionType === 'archive') onArchive();
        if (actionType === 'delete') onDelete();
        handleCloseModal();
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {/* Botão Arquivar */}
            <IconButton onClick={() => handleOpenModal('archive')}>
                <ArchiveIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>

            {/* Botão Excluir */}
            <IconButton onClick={() => handleOpenModal('delete')}>
                <DeleteIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>

            {/* Botão Editar */}
            <IconButton onClick={onEdit}>
                <EditIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>

            {/* Modal de Confirmação */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>{actionType === 'archive' ? 'Confirmar Arquivamento' : 'Confirmar Exclusão'}</DialogTitle>
                <DialogContent>Tem certeza que deseja {actionType === 'archive' ? 'arquivar' : 'excluir'} este item?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="inherit">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default IconButtons;
