import React, { useState } from 'react';
import { FormControl, InputLabel, Select, Box, MenuItem, OutlinedInput, Chip, TextField } from '@mui/material';
import { useFetch } from '../hooks/useFetch';

export default function SelectMultipleProfessional({ value, onChange, name, error: err }) {
    const { data, error, mutate, isFetching } = useFetch('/professional');
    const [search, setSearch] = useState(''); // Estado para o filtro de pesquisa

    // Filtra os profissionais com base no texto da pesquisa
    const filteredData = data?.filter(item => item.User.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <FormControl error={Boolean(err)} fullWidth sx={{ marginTop: 2 }}>
            <InputLabel error={err} id="select-multiple-professional">
                Profissionais
            </InputLabel>
            <Select
                fullWidth
                multiple
                name={name}
                value={value || []}
                onChange={onChange}
                input={<OutlinedInput label={isFetching ? 'Carregando...' : 'Profissionais'} />}
                renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map(value => {
                            const item = data?.find(({ id }) => id === value);
                            return item ? <Chip key={value} label={item.User.name} /> : null;
                        })}
                    </Box>
                )}>
                {/* Campo de pesquisa */}
                <Box sx={{ px: 2, py: 1 }}>
                    <TextField fullWidth placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} size="small" />
                </Box>

                {/* Lista de profissionais filtrados */}
                {filteredData?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.User.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
