import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {
    Paper,
    Chip,
    Divider,
    Typography,
    AppBar,
    Toolbar,
    Button,
    Slide,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Grid,
    Tabs,
    Tab,
    tabClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import LoadIcon from '@mui/icons-material/Update';
import InProfressIcon from '@mui/icons-material/PublishedWithChanges';

// ICONES =====
import CheckCircle from '@mui/icons-material/CheckCircleOutlineSharp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// CAIXA DE DIALOGO =========
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import { useAuth } from '../../../contexts/AuthContext';

// SLIDE DE IMAGENS =====
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import GlobalPaper from '../../../components/GlobalPaper';
import MapChekin from './MapChekin';
import AnswersChecklist from './AnswersChecklist';
// import '../styles.css'
import InProgressIcon from '@mui/icons-material/PublishedWithChanges';

const ViewDetailsButton = ({ params, id }) => {
    const { serviceDetailDialog, setServiceDetailDialog } = useAuth();

    const navigation = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => setServiceDetailDialog(params.row)}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
    },
    {
        field: 'scheduled',
        headerName: 'Dia',
        width: 100,
        valueGetter: params => format(new Date(params.row.scheduled), 'd/M/Y'),
    },
    {
        field: 'checkin',
        headerName: 'Checkin',
        width: 120,
        renderCell: params => <CheckCircle sx={{ ml: '2.5rem' }} color={params.row.checkin ? 'success' : 'error'} />,
    },
    {
        field: 'signature',
        headerName: 'Assinatura',
        width: 120,
        renderCell: params => <CheckCircle sx={{ ml: '2.5rem' }} color={params.row.signature ? 'success' : 'error'} />,
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: params =>
            params.value ? (
                <Chip color="success" size="small" label="Concluído" icon={<CheckIcon />} />
            ) : params.row.checkin ? (
                <Chip size="small" color="warning" icon={<InProgressIcon />} label="Executando" />
            ) : (
                <Chip size="small" icon={<LoadIcon />} label="Aguardando" />
            ),
    },
    {
        field: 'actions',
        type: 'actions',
        width: 100,
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function ListServices({ data }) {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const { Services } = data;

    // CONSTANTES CAIXA DE DIALOGO ======
    const { serviceDetailDialog, setServiceDetailDialog } = useAuth();
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('lg');

    // CAIXA DE DIALOGO ASSINATURA
    const [opennn, setOpennn] = React.useState(false);

    const handleClickOpen = () => {
        setOpennn(true);
    };

    const handleClose = () => {
        setOpennn(false);
    };

    // CAIXA DE DIALOGO FOTOS
    const [viewFullImage, setFullImage] = useState(false);

    const [tab, setTab] = useState(0);

    return (
        <>
            <GlobalPaper>
                <Typography variant="h3" sx={{ mb: '2rem', color: '#284db9' }}>
                    Serviços
                </Typography>
                <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={newPage => setPage(newPage)}
                        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        rows={Services}
                        rowsPerPageOptions={[10, 25, 100]}
                        disableSelectionOnClick
                    />
                </Box>
            </GlobalPaper>

            <Dialog fullWidth={fullWidth} maxWidth="sm" open={Boolean(serviceDetailDialog)} onClose={() => setServiceDetailDialog()}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1, color: '#FAFAFA' }} variant="h6" component="div">
                            Detalhes
                        </Typography>
                        <IconButton edge="end" onClick={() => setServiceDetailDialog()} aria-label="close" sx={{ color: '#FAFAFA' }}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={(e, tab) => setTab(tab)} aria-label="basic tabs example">
                        <Tab label="Observações" id="tab-1" />
                        <Tab label="Checkin" id="tab-2" />
                        <Tab label="Assinatura" id="tab-3" />
                        <Tab label="Checklist" id="tab-3" />
                        <Tab label="Fotos" id="tab-3" />
                    </Tabs>
                </Box>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item xs={12} sx={{ display: tab === 0 ? 'block' : 'none' }}>
                            <Typography>{serviceDetailDialog?.report ? serviceDetailDialog?.report : 'Nenbuma observação enviada.'}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ display: tab === 1 ? 'block' : 'none', minHeight: '500px', minWidth: '500px' }}>
                            {serviceDetailDialog?.checkin ? (
                                <MapChekin points={[serviceDetailDialog?.checkin]} />
                            ) : (
                                <Typography>Chekin não realizado ou localização não coletada.</Typography>
                            )}
                            {/* {serviceDetailDialog?.checkin} */}
                        </Grid>

                        <Grid item xs={12} sx={{ display: tab === 2 ? 'block' : 'none' }}>
                            {serviceDetailDialog?.signature_owner && <Typography>Responsável: {serviceDetailDialog?.signature_owner}</Typography>}
                            {serviceDetailDialog?.signature ? (
                                <img width="400px" height="600px" src={serviceDetailDialog?.signature} />
                            ) : (
                                <Typography>Assinatura não coletada</Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} sx={{ display: tab === 3 ? 'block' : 'none' }}>
                            {serviceDetailDialog?.OrderCheckListAnswers ? (
                                <AnswersChecklist asks={data?.Activity.ActivityAsks} answers={serviceDetailDialog?.OrderCheckListAnswers} />
                            ) : (
                                <Typography>Nenhum checklist respondido</Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} sx={{ display: tab === 4 ? 'block' : 'none' }}>
                            <Stack direction="row" flexWrap="wrap">
                                {serviceDetailDialog?.ServiceImages.map(item => (
                                    <Box
                                        key={item.id}
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            overflow: 'hidden',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            mb: 2,
                                            mr: 2,
                                        }}>
                                        <img onClick={e => setFullImage(item.image_url)} width="100%" minHeight="100%" src={item.image_url} />
                                    </Box>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>

                <Dialog open={viewFullImage} TransitionComponent={Transition} keepMounted>
                    <Stack direction="row" justifyContent="space-between" display="flex" alignItems="center" sx={{ padding: '1rem' }}>
                        <Button onClick={e => setFullImage(false)}>
                            <CloseOutlinedIcon />
                        </Button>
                    </Stack>

                    <DialogContent justifyContent="center">
                        <img src={viewFullImage} width="100%" height="auto" />
                    </DialogContent>
                </Dialog>
            </Dialog>
        </>
    );
}
