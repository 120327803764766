import React, { useState } from 'react';
import { FormControl, InputLabel, Select, Box, MenuItem, OutlinedInput, Chip, TextField } from '@mui/material';
import { useFetch } from '../hooks/useFetch';

export default function SelectMultipleAsks({ value, onChange, name, error: err }) {
    const { data, error, mutate, isFetching } = useFetch('/ask');
    const [search, setSearch] = useState('');

    // Filtra os itens com base na pesquisa
    const filteredData = data?.filter(item => item.question.toLowerCase().includes(search.toLowerCase())) || [];

    return (
        <FormControl error={Boolean(err)} fullWidth sx={{ marginTop: 2 }}>
            <InputLabel error={err} id="select-multiple-asks">
                Checklists
            </InputLabel>
            <Select
                fullWidth
                multiple
                name={name}
                value={value || []}
                onChange={onChange}
                input={<OutlinedInput label={isFetching ? 'Carregando...' : 'Checklists'} />}
                renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map(value => {
                            const item = data?.find(({ id }) => id === value);
                            return item ? <Chip key={value} label={item.question} /> : null;
                        })}
                    </Box>
                )}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 300, // Limita a altura do menu
                            overflow: 'auto',
                        },
                    },
                }}>
                {/* Caixa de Pesquisa */}
                <Box sx={{ p: 1 }}>
                    <TextField fullWidth placeholder="Pesquisar..." size="small" value={search} onChange={e => setSearch(e.target.value)} />
                </Box>

                {/* Itens Filtrados */}
                {filteredData.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.question}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
