import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useFetch } from '../../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import LoadingContainer from '../../../components/LoadingContent';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import LoadIcon from '@mui/icons-material/Update';
import InProgressIcon from '@mui/icons-material/PublishedWithChanges';
import lodash from 'lodash';
import { format } from 'date-fns';
import WarningIcon from '@mui/icons-material/Warning';
import { isPast } from 'date-fns';
import ActionsComponent from '../../../components/ActionsComponent';
import { archivedOrder, deleteOrder } from '../../../services/v2/orders';

const ViewDetailsButton = ({ params, id, progress, status }) => {
    const navigation = useNavigate();
    const handleArchive = async () => {
        await archivedOrder({ id });
    };

    const handleDelete = async () => {
        if (progress > 0 && status !== 'CONCLUDED') {
            alert('Proibido deletar OS em andamento');
        } else {
            await deleteOrder({ id });
        }
    };

    return <ActionsComponent onArchive={handleArchive} onDelete={handleDelete} onEdit={() => navigation(`/orders/${id}`)} />;
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
    },
    {
        field: 'Customer',
        headerName: 'Cliente',
        minWidth: 210,
        flex: 1,
        valueGetter: params => params.row.Customer.customer_name,
    },
    {
        field: 'createdAt',
        headerName: 'Data',
        width: 100,
        valueGetter: params => format(new Date(params.row.createdAt), 'dd/MM/yyyy'),
    },
    {
        field: 'Professional',
        headerName: 'Prestador',
        width: 150,
        valueGetter: params => params.row.Professional.User.name,
    },
    {
        field: 'Activity',
        headerName: 'Atividade',
        width: 150,
        valueGetter: params => params.row.Activity.name,
    },
    {
        field: 'Services',
        headerName: 'Andamento',
        width: 100,
        renderCell: params => {
            const totalServices = params.row.Services.length;
            const openeds = lodash.countBy(params.row.Services, c => c.status).true || 0;

            // Calcula a porcentagem de progresso
            let progress = (openeds * 100) / totalServices;
            let label = `${Math.round(progress)}%`;

            // Se todos os serviços estão concluídos, mas o status não for 'CONCLUDED'
            if (progress === 100 && params.row.status !== 'CONCLUDED') {
                progress = 90;
                label = '90%';
            }

            return (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress size={40} variant="determinate" value={progress} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Typography variant="caption" component="div" sx={{ fontSize: 11 }}>
                            {label}
                        </Typography>
                    </Box>
                </Box>
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: params => {
            const openeds = lodash.countBy(params.row.Services, c => c.status).true || 0;
            const progress = (openeds * 100) / params.row.Services.length;

            return (
                <>
                    {progress === 100 && params.row.status === 'CONCLUDED' ? (
                        <>
                            <Chip color="success" size="small" label="Concluído" icon={<CheckIcon />} />
                        </>
                    ) : params.value === 'IN_PROGRESS' ? (
                        <Grid container direction={'column'} spacing={0.5}>
                            <Grid item></Grid>
                            {params.row.Services.map(({ scheduled }) => isPast(new Date(scheduled), new Date())).includes(true) ? (
                                <Grid item>
                                    <Chip size="small" color="warning" label="Em atraso" icon={<WarningIcon />} />
                                </Grid>
                            ) : (
                                <Chip size="small" color="info" label="Em andamento" icon={<InProgressIcon />} />
                            )}
                        </Grid>
                    ) : (
                        <>
                            {params.row.Services.map(({ scheduled }) => isPast(new Date(scheduled), new Date())).includes(true) ? (
                                <Grid item>
                                    <Chip size="small" color="warning" label="Em atraso" icon={<WarningIcon />} />
                                </Grid>
                            ) : (
                                <Chip size="small" icon={<LoadIcon />} label="Aguardando" />
                            )}
                        </>
                    )}
                </>
            );
        },
    },
    {
        field: 'actions',
        type: 'actions',
        width: 150,
        renderCell: params => {
            const totalServices = params.row.Services.length;
            const openeds = lodash.countBy(params.row.Services, c => c.status).true || 0;

            let progress = (openeds * 100) / totalServices;

            return <ViewDetailsButton params={params} id={params.row.id} progress={progress} status={params.row.status} />;
        },
    },
];

export default function OrdersTable({ data, isFetching, pageSize, setPage, setPageSize }) {
    if (isFetching) return <LoadingContainer />;
    const { rows } = data || [];
    return (
        <Box sx={{ height: '100vh', width: '100%' }}>
            <DataGrid
                style={{ border: 'none' }}
                loading={isFetching}
                columns={columns}
                // pageSize={pageSize}
                // onPageChange={newPage => setPage(newPage)}
                // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rows={rows}
                rowsPerPageOptions={[10, 25, 100]}
                disableSelectionOnClick
            />
        </Box>
    );
}
