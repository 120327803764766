import axios from 'axios';
// export const baseURL =
//     process.env.NODE_ENV === 'production'
//         ? process.env.REACT_APP_ENVIRONMENT === 'develop'
//             ? process.env.REACT_APP_DEV_SERVER
//             : process.env.REACT_APP_PROD_SERVER
//         : process.env.REACT_APP_LOCALHOST_SERVER;
// export const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_LOCALHOST_SERVER : process.env.REACT_APP_DEV_SERVER;
export const baseURL = 'https://develop.dinamiceng.com.br';
// export const baseURL = 'http://localhost:4000';

const api = axios.create({
    baseURL: `${baseURL}/api/v1/`,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(
    response => response,
    error => {
        throw error;
    },
);

export default api;
