import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    CircularProgress,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import GlobalPaper from '../../../components/GlobalPaper';
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOff';
import ExcelIcon from '@mui/icons-material/CloudDownload';
import DatePicker from 'react-multi-date-picker';
import { SelectActivities } from '../../../components/SelectActivities';
import { SelectProfessional } from '../../../components/SelectProfessional';
import { SelectCustomers } from '../../../components/SelectCustomers';
import OrdersTable from '../components/OrderTable';
import gregorian_pt from '../../../global/gregorian_pt';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { useFetch } from '../../../hooks/useFetch';
import api from '../../../services/api';
import { useSnackbar } from 'notistack';
import SearchComponet from '../../../components/SearchComponet';
import { Switch, FormControlLabel } from '@mui/material';

export default function OrdersList() {
    const { enqueueSnackbar } = useSnackbar();

    const [params, setParams] = useState({});
    const [viewFilter, setViewFilter] = useState(false);
    const [status, setStatus] = useState('');
    const [activity_id, setActivity] = useState();
    const [customer_id, setCustomer] = useState();
    const [professional_id, setProfessional] = useState();
    const [scheduled, setScheduled] = useState();

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [name, setName] = useState();
    const [includeArchived, setIncludeArchived] = useState(false);

    const handleToggle = async () => {
        try {
            const newStatus = !includeArchived;
            setIncludeArchived(newStatus);
        } catch (error) {
            console.error('Erro ao atualizar status do filtro:', error);
        }
    };

    const { data, error, mutate, isFetching } = useFetch('/order', {
        params: {
            limit: pageSize,
            page: page,
            name,
            archived: includeArchived,
            ...params,
        },
    });

    const [isDownloading, setDownloading] = useState();
    const downloadExcel = async () => {
        try {
            setDownloading(true);
            console.log({ params });
            const { data } = await api.get(`/search/excel`, {
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'blob',
                params: params,
            });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `Report_${new Date().toLocaleDateString('pt-BR')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloading(false);
        } catch (error) {
            setDownloading(false);
            enqueueSnackbar('Erro. Revise os dados do cadastro', { variant: 'portal' });
        }
    };

    const handleSearch = () => {
        setViewFilter(false);
        setParams({ status, activity_id, professional_id, customer_id, scheduled });
    };

    const PickerDate = ({ openCalendar, value, ...props }) => {
        const parseDate = dateStr => {
            if (!dateStr) return null;
            const [day, month, year] = dateStr.split('/'); // Separa DD/MM/YYYY
            // return new Date(`${year}-${month}-${day}`); // Monta no formato YYYY-MM-DD
            return new Date(Number(year), Number(month) - 1, Number(day), 12);
        };
        const viewValue = !value
            ? ''
            : value.length === 1
            ? new Date(parseDate(value[0])).toLocaleDateString('pt-BR')
            : value.length === 2
            ? `De ${new Date(parseDate(value[0])).toLocaleDateString('pt-BR')} a ${new Date(parseDate(value[1])).toLocaleDateString('pt-BR')}`
            : '';

        return (
            <TextField
                placeholder="Selecione uma ou mais datas"
                fullWidth
                value={viewValue}
                label="Data de Execução"
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end" sx={{ display: viewValue ? 'inherit' : 'none' }}>
                                <IconButton onClick={() => setScheduled()}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <IconButton onClick={openCalendar}>
                                    <CalendarIcon />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        );
    };

    return (
        <>
            <Box component="nav" sx={{ flexGrow: 1, width: { sm: '100%', md: '400px' }, flexShrink: { sm: 0 } }} aria-label="mailbox">
                <Drawer open={viewFilter} anchor="right" variant="temporary" sx={{ width: { xs: '100%', md: '400px' } }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant="h3">Filtro de Serviços</Typography>
                        <IconButton onClick={() => setViewFilter(!viewFilter)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <GlobalPaper sx={{ width: 400 }}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs>
                                {/* <Box sx={{ minWidth: 120 }}> */}
                                <FormControl fullWidth>
                                    <InputLabel id="edducation">Status</InputLabel>
                                    <Select
                                        labelId="edducation"
                                        id="status"
                                        name="status"
                                        value={status}
                                        label="Status"
                                        onChange={e => setStatus(e.target.value)}>
                                        <MenuItem value="">Todos</MenuItem>
                                        <MenuItem value="WAITING">Nao realizados</MenuItem>
                                        <MenuItem value="IN_PROGRESS">Em progresso</MenuItem>
                                        <MenuItem value="CONCLUDED">Concluídos</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* </Box> */}
                            </Grid>
                            <Grid item xs>
                                <SelectActivities label="Atividade" name="activity_id" value={activity_id} onChange={e => setActivity(e)} />
                            </Grid>
                            <Grid item xs>
                                <SelectProfessional
                                    label="Prestador"
                                    name="professional_id"
                                    value={professional_id}
                                    onChange={e => setProfessional(e)}
                                />
                            </Grid>
                            <Grid item xs>
                                <SelectCustomers label="Cliente" name="customer_id" value={customer_id} onChange={e => setCustomer(e)} />
                            </Grid>
                            <Grid item xs>
                                <DatePicker
                                    containerStyle={{ width: '100%' }}
                                    onChange={setScheduled}
                                    range
                                    name="scheduled"
                                    locale={gregorian_pt}
                                    format="DD/MM/YYYY"
                                    // style={{ height: '3.5rem', width: '100%', fontSize: '1rem', fontWeight: '700' }}
                                    // containerStyle={{ width: '100%' }}
                                    // placeholder="Coloque as datas referentes aos serviços aqui"
                                    //minDate={new Date()}
                                    value={scheduled}
                                    required
                                    render={<PickerDate />}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {/* <IconButton color="primary" size="large" onClick={handleSearch}>
                                    <SearchIcon fontSize="inherit" />
                                </IconButton> */}
                                <Button variant="contained" onClick={handleSearch} fullWidth>
                                    Aplicar filtro
                                </Button>
                                <Button
                                    onClick={e => {
                                        setParams({});
                                        setViewFilter(false);
                                    }}
                                    fullWidth>
                                    Remover filtro
                                </Button>
                            </Grid>
                        </Grid>
                    </GlobalPaper>
                </Drawer>
            </Box>
            <GlobalPaper elevation={3}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3" sx={{ mb: 3, color: '#284db9' }}>
                        Lista de Ordens de Serviço
                    </Typography>
                    <SearchComponet
                        onSearch={txt => {
                            setName(txt);
                        }}
                        label="Digite o nome do prestador ou cliente"
                    />
                    <FormControlLabel
                        control={<Switch checked={includeArchived} onChange={handleToggle} color="primary" />}
                        label="Exibir Arquivados"
                    />
                    <Box>
                        <Stack direction="row" spacing={2}>
                            <IconButton
                                disabled={!Boolean(data?.rows.length)}
                                sx={{ color: Boolean(data?.rows.length) ? '#62e521' : 'inherit' }}
                                onClick={() => downloadExcel()}>
                                {isDownloading ? <CircularProgress size={30} color="inherit" /> : <ExcelIcon />}
                            </IconButton>
                            <IconButton sx={{}} onClick={() => setViewFilter(true)}>
                                {Boolean(Object.keys(params).length) ? <FilterIcon style={{ color: '#284db9' }} /> : <FilterOffIcon />}
                            </IconButton>
                        </Stack>
                    </Box>
                </Stack>
                <OrdersTable params={params} data={data} isFetching={isFetching} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} />
            </GlobalPaper>
        </>
    );
}
