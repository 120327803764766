import { GoogleMap, InfoWindow, InfoWindowF, Marker, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState, memo, useRef } from 'react';
import LoadingContainer from '../../../components/LoadingContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Link, Stack } from '@mui/material';

const MarkerAndInfoWindow = ({ item, map }) => {
    const { checkin, Order } = item;
    const markerRef = useRef();
    const infoRef = useRef();
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [infoWindowOpen, setInfoWindowOpen] = useState(false); // Estado para controlar visibilidade do InfoWindow

    // Extrair informações necessárias
    const { name, picture } = Order.Professional?.User || {};
    const status = Order.status;
    const scheduledDate = new Date(Order.scheduled);

    // Definir cor da borda com base no status e na data programada
    const currentDate = new Date();
    let borderColor = 'grey'; // Default

    if (status === 'IN_PROGRESS') {
        borderColor = scheduledDate < currentDate ? 'red' : 'orange';
    } else if (status === 'CONCLUDED') {
        borderColor = 'green';
    }

    // Função para criar o avatar dinâmico
    const createAvatar = async () => {
        const size = 42;
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');

        // Desenhar o círculo de borda
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2 - 5, 0, 2 * Math.PI);
        ctx.lineWidth = 3;
        ctx.strokeStyle = borderColor;
        ctx.stroke();

        if (picture) {
            // Se houver uma imagem, desenhe-a
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = picture;
            img.onload = () => {
                ctx.save();
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2 - 8, 0, 2 * Math.PI);
                ctx.clip();
                ctx.drawImage(img, 0, 0, size, size);
                ctx.restore();
                setAvatarUrl(canvas.toDataURL());
            };
        } else {
            // Desenhar as iniciais do nome
            const initials = name
                ? name
                      .split(' ')
                      .map(word => word[0])
                      .join('')
                      .slice(0, 2)
                      .toUpperCase()
                : 'NA';

            ctx.fillStyle = '#003366'; // Fundo azul escuro
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2 - 8, 0, 2 * Math.PI);
            ctx.fill();

            ctx.fillStyle = '#fff'; // Texto branco
            ctx.font = 'bold 12px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(initials, size / 2, size / 2);

            setAvatarUrl(canvas.toDataURL());
        }
    };

    useEffect(() => {
        createAvatar();
    }, [picture, name, borderColor]);

    return (
        <>
            {avatarUrl && (
                <MarkerF
                    visible={true}
                    onLoad={marker => (markerRef.current = marker)}
                    onClick={() => setInfoWindowOpen(true)} // Abre o InfoWindow ao clicar no Marker
                    position={{ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }}
                    icon={{
                        url: avatarUrl,
                        scaledSize: new window.google.maps.Size(42, 42),
                    }}
                />
            )}
            {infoWindowOpen && (
                <InfoWindowF
                    onLoad={info => (infoRef.current = info)}
                    anchor={markerRef.current}
                    position={{ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }}
                    onCloseClick={() => setInfoWindowOpen(false)} // Fecha o InfoWindow ao clicar no botão de fechar
                >
                    <Stack direction="column">
                        <Typography variant="body">
                            <strong>Prestador:</strong> {Order.Professional?.User.name}
                        </Typography>
                        <Typography variant="body">
                            <strong>Cliente:</strong> {Order.Customer?.customer_name}
                        </Typography>
                        <Typography variant="body">
                            <strong>Atividade:</strong> {Order.Activity?.name}
                        </Typography>
                        <Button sx={{ mt: 2 }} size="small" href={`/orders/${Order.id}`} variant="contained">
                            Detalhes da ordem
                        </Button>
                    </Stack>
                </InfoWindowF>
            )}
        </>
    );
};

export default ({ points }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEKEY,
    });

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    const [map, setMap] = useState(null);

    const [center, setCenter] = useState({
        lat: -16.6956554,
        lng: -49.2913654,
    });

    const containerStyle = {
        width: '100%',
        minHeight: '100%',
    };

    useEffect(() => {
        if (Boolean(points.length) && Boolean(map)) {
            const bounds = new window.google.maps.LatLngBounds();
            points.forEach(({ checkin }) => bounds.extend({ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }));
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [points, map]);

    const onLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(map => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18} onLoad={onLoad} onUnmount={onUnmount}>
            {points?.map(item => {
                return <MarkerAndInfoWindow key={item.id} item={item} map={map} />;
            })}
        </GoogleMap>
    ) : (
        <LoadingContainer label="Carregando mapa" />
    );
};
