import React, { useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import GlobalPaper from '../../../components/GlobalPaper';
import SearchComponet from '../../../components/SearchComponet';
import ActionsComponent from '../../../components/ActionsComponent';
import { useFetch } from '../../../hooks/useFetch';
import { archivedProfissional, deleteProfissional } from '../../../services/v2/professional';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import LoadingContainer from '../../../components/LoadingContent';
import { Switch, FormControlLabel } from '@mui/material';

const ViewDetailsButton = ({ params, id }) => {
    const navigation = useNavigate();

    const handleArchive = () => {
        archivedProfissional({ id });
    };

    const handleDelete = () => {
        deleteProfissional({ id });
    };

    const handleEdit = () => {
        navigation(`/team/${id}`);
    };

    return <ActionsComponent onArchive={handleArchive} onDelete={handleDelete} onEdit={handleEdit} />;
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
    },
    {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
    },
    {
        field: 'email',
        headerName: 'E-mail',
        width: 400,
    },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default function ListUsers() {
    const navigation = useNavigate();

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [name, setName] = useState('');
    const [includeArchived, setIncludeArchived] = useState(false);

    const handleToggle = async () => {
        try {
            const newStatus = !includeArchived;
            setIncludeArchived(newStatus);
        } catch (error) {
            console.error('Erro ao atualizar status do filtro:', error);
        }
    };

    const { data, error, mutate, isFetching } = useFetch('/user', {
        params: {
            limit: pageSize,
            page: page,
            name,
            archived: includeArchived,
        },
    });

    // Debounced search function to avoid sending requests too frequently
    const debouncedSearch = useCallback(
        debounce(searchText => {
            setName(searchText); // Update search name after debounce
        }, 500), // Delay 500ms after user stops typing
        [],
    );

    // Desestruturando as linhas de dados
    const { rows = [] } = data || {}; // Previne erro se data for undefined ou null

    return (
        <>
            <GlobalPaper elevation={3}>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="h3" sx={{ mb: 3, color: '#284db9' }}>
                        Lista de Usuários e Prestadores
                    </Typography>
                    <SearchComponet
                        onSearch={debouncedSearch} // Using debounced search
                        label="Digite o nome ou e-mail"
                    />
                    <FormControlLabel
                        control={<Switch checked={includeArchived} onChange={handleToggle} color="primary" />}
                        label="Exibir Arquivados"
                    />
                </Box>
                {/* Exibindo o carregamento ou a tabela dependendo do estado */}
                {isFetching ? (
                    <LoadingContainer />
                ) : (
                    <Box sx={{ height: '100vh', width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            style={{ border: 'none' }}
                            loading={isFetching}
                            pageSize={pageSize}
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                            rows={rows}
                            rowsPerPageOptions={[10, 25, 100]}
                            disableSelectionOnClick
                        />
                    </Box>
                )}
            </GlobalPaper>
        </>
    );
}
