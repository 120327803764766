import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ onSearch, label = 'Pesquisar' }) => {
    const [searchText, setSearchText] = useState('');

    const handleSearch = event => {
        const query = event.target.value;
        setSearchText(query);

        // Envia a busca se o texto tiver mais de 2 caracteres ou for uma string vazia
        if (query.length > 2) {
            onSearch(query);
        } else if (query.length < 2) {
            onSearch('');
        }
    };

    const handleIconClick = () => {
        // Se o texto for menor que 2 caracteres, envia uma string vazia
        if (searchText.length > 2) {
            onSearch(searchText);
        } else {
            onSearch('');
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, width: '50%' }}>
            <TextField label={label} variant="outlined" fullWidth value={searchText} onChange={handleSearch} sx={{ mr: 2, color: '#284db9' }} />
            <IconButton onClick={handleIconClick} color="primary">
                <SearchIcon />
            </IconButton>
        </Box>
    );
};

export default SearchBar;
