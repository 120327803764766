import api from '../../../services/api';

export const archivedOrder = async ({ id }) => {
    const { data } = await api.patch(`/order/${id}/archive`);
    return data;
};
export const deleteOrder = async ({ id }) => {
    const { data } = await api.delete(`/order/${id}`);
    return data;
};
