import * as React from 'react';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/SendRounded';
import { useState } from 'react';
import api from '../services/api';
import { useEffect } from 'react';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useRef } from 'react';

import notynew from '../assets/notynew.mp3';
import { useApp } from '../contexts/AppContext';

export default function SupportChat({ data, mutate, isFetching, service, order_id }) {
    const { user, socket } = useAuth();
    const { newMsgSound } = useApp();
    const bottomRef = useRef();
    const inputRef = useRef();
    const [message, setMessage] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);

    const pushMessage = response => {
        newMsgSound();
        data.push(response);
        mutate({ data: [...data] });
    };
    useEffect(() => {
        socket.on('admin', response => {
            pushMessage(response);
        });

        return () => socket.off('admin');
    }, []);

    const handleSendMesssage = async () => {
        try {
            setSubmitting(true);
            const response = await api.post(`/chat/${service.id}`, {
                sender_id: user.id,
                message: message,
                service_id: service.id,
                order_id: order_id,
            });
            setMessage('');

            setSubmitting(false);
            socket.emit('forService', { ...response.data.data, user_id: service.Order.professional_id });
            data.push(response.data.data);
            await mutate({ data: [...data] });
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
            inputRef.current.focus();
        } catch (err) {
            setSubmitting(false);
            console.log(err);
        }
    };

    useEffect(() => {
        bottomRef.current?.scrollIntoView();
    }, [data]);

    return (
        <>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    {data?.map((item, k) => (
                        <Grid
                            ref={e => {
                                if (k === data.length - 1) bottomRef.current = e;
                            }}
                            key={k}
                            container
                            item
                            justifyContent={user.id === item.sender_id ? 'flex-end' : 'flex-start'}>
                            <Grid
                                item
                                sx={{
                                    maxWidth: '75%',
                                    backgroundColor: `${user.id === item.sender_id ? '#9ad9d4' : '#eee'}`,
                                    padding: '8px 20px',
                                    borderRadius: '10px',
                                }}>
                                <Typography>{item.message}</Typography>
                                <Typography sx={{ fontSize: 10, textAlign: 'right' }}>
                                    {new Date(item.createdAt).toLocaleTimeString('pt-BR')}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <TextField
                    autoFocus
                    required
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={e => {
                        if (e.keyCode === 13) handleSendMesssage();
                    }}
                    inputRef={inputRef}
                    value={message}
                    margin="dense"
                    label="Escreva sua mensagem"
                    type="text"
                    name="name"
                    fullWidth
                    variant="outlined"
                    disabled={isSubmitting}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSendMesssage} disabled={!Boolean(message)}>
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogActions>
        </>
    );
}
