import { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@mui/material';
import api from '../../services/api';

export default function OrderStatus({ orderId, currentStatus, onStatusUpdated }) {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(currentStatus);

    // Função para tratar a atualização de status
    const handleUpdateStatus = async stts => {
        try {
            await api.put(`/order/status/${orderId}`, { status: stts });
            onStatusUpdated(status); // Callback para atualizar o estado do componente pai
            setOpen(false);
            setStatus(stts);
        } catch (error) {
            console.error('Erro ao atualizar status:', error);
            alert('Falha ao atualizar o status da ordem, verifique sua conexão ou atualize a página');
        }
    };

    // Mapeamento de cores para os status
    const statusColors = {
        WAITING: '#ece249',
        IN_PROGRESS: '#35adb9',
        CONCLUDED: '#4ca923',
    };

    // Mapeamento de status para português
    const statusLabels = {
        WAITING: 'Aguardando',
        IN_PROGRESS: 'Em Andamento',
        CONCLUDED: 'Concluído',
    };

    return (
        <>
            <Box
                onClick={() => setOpen(true)}
                sx={{
                    padding: '0.3rem 0.6rem',
                    backgroundColor: statusColors[status],
                    color: '#fff',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '0.9rem',
                    marginTop: '0.5rem',
                }}>
                {statusLabels[status]}
            </Box>

            {/* Modal para editar o status */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Atualizar Status da Ordem</DialogTitle>
                <DialogContent>
                    <Select value={status} onChange={e => handleUpdateStatus(e.target.value)} fullWidth sx={{ marginTop: '1rem' }}>
                        <MenuItem value="WAITING">Aguardando</MenuItem>
                        <MenuItem value="IN_PROGRESS">Em Andamento</MenuItem>
                        <MenuItem value="CONCLUDED">Concluído</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
