import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { Badge, Chip, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/ChatBubble';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useApp } from '../contexts/AppContext';
import { SupportAsside } from './SupportAsside';
import FreshActions from './FreshActions';
import logo from '../assets/full-logo.png';
import miniLogo from '../assets/logo.png';

const drawerWidth = 240;

function ContainerGlobal({ children, window }) {
    const { newChats } = useApp();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [viewSuport, setViewSuport] = useState(false);
    const { LogOut, user } = useAuth();
    const location = useLocation();
    const navigation = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { label: 'Dashboard', path: '/dashboard', icon: <HomeIcon /> },
        { label: 'Ordens de Serviço', path: '/orders', icon: <SignalCellularAltIcon /> },
        { label: 'Tracker', path: '/tracker', icon: <WhereToVoteIcon /> },
        { label: 'Equipe', path: '/team', icon: <PersonIcon /> },
        { label: 'Clientes', path: '/customers', icon: <InsertDriveFileIcon /> },
        { label: 'Configurações', path: '/settings', icon: <SettingsIcon /> },
    ];

    const drawer = (
        <div>
            <Box component="img" src={logo} alt="Logo" sx={{ width: '150px', ml: '1.2rem', my: '2rem' }} />
            <List>
                {menuItems.map(item => (
                    <ListItem disablePadding key={item.path}>
                        <ListItemButton onClick={() => navigation(item.path)}>
                            <ListItemIcon
                                sx={{
                                    color: location.pathname === item.path ? '#1E45B3' : 'inherit',
                                }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                sx={{
                                    color: location.pathname === item.path ? '#1E45B3' : 'inherit',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}

                <ListItem disablePadding>
                    <ListItemButton onClick={LogOut}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}>
                    <Toolbar
                        style={{
                            backgroundImage: 'linear-gradient(to right, #1E45B3, #3857bd, #677dc2, #a8b3d3)',
                        }}
                        sx={{ justifyContent: 'space-between' }}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>

                        <Box component="img" src={miniLogo} alt="Logo" sx={{ width: '50px' }} />

                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            <IconButton edge="end" onClick={() => setViewSuport(!viewSuport)}>
                                <Badge badgeContent={newChats.length} color="error">
                                    <ChatIcon style={{ color: '#fff' }} />
                                </Badge>
                            </IconButton>
                            <Chip style={{ color: '#fff' }} label={user.name} />
                        </Stack>
                    </Toolbar>
                </AppBar>

                <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}>
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open>
                        {drawer}
                    </Drawer>
                </Box>

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 4,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                    }}>
                    <Toolbar />
                    {children}
                </Box>

                <Drawer open={viewSuport} anchor="right" variant="temporary">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant="h6" style={{ color: '#284db9' }}>
                            Suporte
                        </Typography>
                        <IconButton onClick={() => setViewSuport(!viewSuport)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <SupportAsside />
                </Drawer>
            </Box>
            <FreshActions />
        </>
    );
}

ContainerGlobal.propTypes = {
    children: PropTypes.node.isRequired,
    window: PropTypes.func,
};

export default ContainerGlobal;
