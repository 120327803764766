import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Dialog,
    DialogContent,
    Box,
    Button,
    DialogTitle,
    Grid,
    Stack,
    Table,
    TableCell,
    TableRow,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { colors } from '../../../global/helpers';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

ChartJS.register(ArcElement, Tooltip, Legend);

export function Graph({ data }) {
    const graph = {
        labels: data?.slice(0, 12).map(i => i['Activity.name']),
        datasets: [
            {
                label: 'Atividade mais executadas',
                data: data?.slice(0, 12).map(i => Number(i.total_activity)),
                backgroundColor: data?.slice(0, 12).map((i, k) => colors[k]),
            },
        ],
    };

    const [dialog, setDialog] = useState(false);

    // Detecta se está em um dispositivo mobile
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card elevation={3} sx={{ borderRadius: '8px', flexGrow: 2, py: 5, alignItems: 'center' }}>
            <CardContent>
                <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    px={isMobile ? 2 : 4}
                    spacing={isMobile ? 2 : 0}
                    alignItems={isMobile ? 'center' : 'flex-start'}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                color: '#284db9',
                                marginBottom: 22,
                                fontSize: isMobile ? '1.3rem' : '1.25rem',
                                textAlign: isMobile ? 'center' : 'left',
                            }}>
                            Atividades por execução
                        </Typography>
                        {data?.slice(0, 12).map((i, k) => (
                            <Stack key={k} direction="row" spacing={2} sx={{ mb: 1, justifyContent: isMobile ? 'flex-start' : 'flex-start' }}>
                                <Box sx={{ minWidth: 22, minHeight: 22, maxHeight: 22, borderRadius: 11, backgroundColor: colors[k] }} />
                                <Typography style={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                                    {`${i['Activity.name']} - ${i.total_activity}`}
                                </Typography>
                            </Stack>
                        ))}

                        <Button onClick={() => setDialog(true)} variant="contained" sx={{ mt: 3 }}>
                            Ver todos
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Doughnut
                            width={isMobile ? 200 : 250}
                            height={isMobile ? 200 : 250}
                            options={{ responsive: true, plugins: { legend: { display: false } } }}
                            data={graph}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <Dialog open={dialog} keepMounted maxWidth="md" fullWidth>
                <Stack direction="row" justifyContent="space-between" display="flex" alignItems="center" sx={{ padding: '1rem' }}>
                    <DialogTitle>Atividades por execução</DialogTitle>
                    <Button onClick={() => setDialog(false)}>
                        <CloseOutlinedIcon />
                    </Button>
                </Stack>

                <DialogContent>
                    <Table>
                        {data.map(item => (
                            <TableRow key={item['Activity.name']}>
                                <TableCell>{item['Activity.name']}</TableCell>
                                <TableCell>{item.total_activity}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </DialogContent>
            </Dialog>
        </Card>
    );
}
