import { LoadingButton } from '@mui/lab';
import { Box, Container, Divider, Grid, Rating, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import GlobalPaper from '../../components/GlobalPaper';
import LoadingContainer, { NothingContent } from '../../components/LoadingContent';
import { useFetch } from '../../hooks/useFetch';
import api from '../../services/api';
// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import AnswersChecklist from '../../components/AnswersChecklist';

export default function Shared() {
    const { uuid } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`/rating/${uuid}`);

    const { enqueueSnackbar } = useSnackbar();

    const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } = useFormik({
        initialValues: {
            rating_value: 0,
            message: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await api.post(`/rating/${uuid}`, values);
                enqueueSnackbar('Avaliação enviada', { variant: 'success' });
            } catch (error) {
                enqueueSnackbar('Erro ao avaliar');
                console.log(error);
            }
        },
    });

    if (error) return <NothingContent label="Ordem de serviço indisponível" />;

    if (isFetching) return <LoadingContainer></LoadingContainer>;

    if (Boolean(data.Rating)) return <NothingContent label="Ordem de serviço já foi avaliada" />;

    console.log(data);
    return (
        <Container>
            <Typography variant="h3" sx={{ my: 4, fontSize: 30 }} component="legend">
                Relatório de avaliação
            </Typography>
            <GlobalPaper>
                <Grid container direction="column" rowGap={4}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Atividade: {data.Activity.name}</Typography>
                        <Typography variant="h6">Profissional: {data.Professional.User.name}</Typography>
                        {/* <Typography>Atividade: {data.Activity.name}</Typography> */}
                    </Grid>
                    {data.Services.map(item => (
                        <Grid key={item.id} item container sx={{ border: '1px solid #ccc', borderRadius: 2, p: 3 }}>
                            {item.status && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h3">
                                            Data: {new Date(item.scheduled).toLocaleDateString('pt-Br')}
                                        </Typography>
                                        <Divider sx={{ my: 3 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5">
                                            Fotos
                                        </Typography>
                                        <Stack direction="row" flexWrap="wrap">
                                            {item?.ServiceImages.map(i => (
                                                <Box
                                                    key={i.id}
                                                    sx={{
                                                        width: 150,
                                                        height: 150,
                                                        overflow: 'hidden',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        mb: 2,
                                                        mr: 2,
                                                        border: '1px solid #ccc',
                                                        borderRadius: 2,
                                                    }}>
                                                    <img
                                                        //onClick={e => setFullImage(item.image_url)}
                                                        width="100%"
                                                        height="100%"
                                                        src={i.image_url}
                                                    />
                                                </Box>
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5">
                                            Assinatura do responsável
                                        </Typography>
                                        <Stack direction="row" flexWrap="wrap">
                                            {item.signature_owner && <Typography gutterBottom>Responsável: {item.signature_owner}</Typography>}
                                            {item.signature ? (
                                                <Box
                                                    sx={{
                                                        width: 150,
                                                        height: 150,
                                                        overflow: 'hidden',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        mb: 2,
                                                        mr: 2,
                                                        border: '1px solid #ccc',
                                                        borderRadius: 2,
                                                    }}>
                                                    <img
                                                        //onClick={e => setFullImage(item.image_url)}
                                                        width="100%"
                                                        height="100%"
                                                        src={item.signature}
                                                    />
                                                </Box>
                                            ) : (
                                                'Assinatura não foi coletada'
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5">
                                            Checklist
                                        </Typography>
                                        {Boolean(item?.OrderCheckListAnswers.length) ? (
                                            <AnswersChecklist asks={data?.Activity.ActivityAsks} answers={item?.OrderCheckListAnswers} />
                                        ) : (
                                            <Typography>Nenhum checklist respondido</Typography>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </GlobalPaper>
            <GlobalPaper>
                <Grid container rowGap={4}>
                    <Grid item xs={12} alignItems="center">
                        <Typography variant="h3" gutterBottom>
                            Avalie o trabalho realizado
                        </Typography>
                        <Rating
                            name="Avalie o atendimento"
                            value={values.rating_value}
                            onChange={(e, selected) => setFieldValue('rating_value', selected)}
                            size="large"
                            sx={{ my: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            onChange={handleChange}
                            value={values.message}
                            name="message"
                            label="Mensagem/Sugestão"
                        />
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <LoadingButton onClick={handleSubmit} loading={isSubmitting} variant="contained">
                            Concluir avaliação
                        </LoadingButton>
                    </Grid>
                </Grid>
            </GlobalPaper>
        </Container>
    );
}
