import api from '../../../services/api';

export const archivedQuestion = async ({ id }) => {
    const { data } = await api.patch(`/ask/archive/${id}`);
    return data;
};
export const deleteQuestion = async ({ id }) => {
    const { data } = await api.delete(`/ask/${id}`);
    return data;
};
