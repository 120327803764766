import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import ListAsks from './ListAsks';
import { useApp } from '../../../contexts/AppContext';
import SearchComponet from '../../../components/SearchComponet';
import { Switch, FormControlLabel } from '@mui/material';

export default function TabAsks() {
    const { setOpenAsk } = useApp();
    const [name, setName] = useState();

    const [includeArchived, setIncludeArchived] = useState(false);

    const handleToggle = async () => {
        try {
            const newStatus = !includeArchived;
            setIncludeArchived(newStatus);
        } catch (error) {
            console.error('Erro ao atualizar status do filtro:', error);
        }
    };

    return (
        <>
            <Stack sx={{ alignItems: 'flex-end', pt: 2, pb: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                <SearchComponet
                    onSearch={text => {
                        setName(text);
                    }}
                />
                <FormControlLabel control={<Switch checked={includeArchived} onChange={handleToggle} color="primary" />} label="Exibir Arquivados" />
                <Button onClick={() => setOpenAsk(true)} sx={{ color: '#FAFAFA' }} variant="contained">
                    Adicionar checklist
                </Button>
            </Stack>
            <ListAsks name={name} archived={includeArchived} />
        </>
    );
}
