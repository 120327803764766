import api from '../../api';

export const archivedActivity = async ({ id }) => {
    const { data } = await api.patch(`/activity/archive/${id}`);
    return data;
};
export const deleteActivity = async ({ id }) => {
    const { data } = await api.delete(`/activity/${id}`);
    return data;
};
