import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer from '../../../components/LoadingContent';
import { Box, Button } from '@mui/material';
import DialogAddActivity from './DialogAddActivity';
import { DataGrid } from '@mui/x-data-grid';
import { useApp } from '../../../contexts/AppContext';
import ActionsComponent from '../../../components/ActionsComponent';
import { deleteActivity, archivedActivity } from '../../../services/v2/activities';
import { Switch, FormControlLabel } from '@mui/material';

const ViewDetailsButton = ({ params, item }) => {
    const { setEditAct, setOpenAct } = useApp();
    const handleArchive = async () => {
        await archivedActivity({ id: item.id });
    };

    const handleDelete = async () => {
        await deleteActivity({ id: item.id });
    };

    const handleEditActivity = () => {
        setEditAct(item);
        setOpenAct(true);
    };
    return <ActionsComponent onArchive={handleArchive} onDelete={handleDelete} onEdit={() => handleEditActivity()} />;
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'name',
        headerName: 'Atividade',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} item={params.row} />,
    },
];

export default function ListActivities({ handleClickOpen, handleClose, open }) {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [includeArchived, setIncludeArchived] = useState(false);
    const { openAct, setOpenAct, editAct, setEditAct } = useApp();

    const { data, error, mutate, isFetching } = useFetch('/activity', {
        params: {
            limit: pageSize,
            page: page,
            paged: true,
            archived: includeArchived,
        },
    });

    const handleToggle = async () => {
        try {
            const newStatus = !includeArchived;
            setIncludeArchived(newStatus);
        } catch (error) {
            console.error('Erro ao atualizar status do filtro:', error);
        }
    };

    if (isFetching) return <LoadingContainer />;

    const { rows, pagination } = data;

    return (
        <>
            <Box sx={{ height: '100vh', width: '100%' }}>
                <FormControlLabel
                    style={{ marginTop: -80 }}
                    control={<Switch checked={includeArchived} onChange={handleToggle} color="primary" />}
                    label="Exibir Arquivados"
                />
                <DataGrid
                    columns={columns}
                    loading={isFetching}
                    pageSize={pageSize}
                    onPageChange={newPage => setPage(newPage)}
                    onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                    rows={rows}
                    rowsPerPageOptions={[10, 25, 100]}
                    disableSelectionOnClick
                />
            </Box>
            <DialogAddActivity
                handleClose={() => {
                    setOpenAct(false);
                    setEditAct();
                }}
                open={openAct}
                mutate={mutate}
                data={editAct}></DialogAddActivity>
        </>
    );
}
