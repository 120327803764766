import React from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import OrderForm from '../components/OrderForm';
import GlobalPaper from '../../../components/GlobalPaper';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import REPORT from '@mui/icons-material/FlagCircle';
import CHECKIN from '@mui/icons-material/ShareLocation';
import STARTED from '@mui/icons-material/PlayCircle';
import CHAT from '@mui/icons-material/MapsUgc';
import ENDED from '@mui/icons-material/StopCircle';
import IMAGES from '@mui/icons-material/PhotoLibrary';
import SIGNATURE from '@mui/icons-material/HistoryEdu';
import CHECKLIST from '@mui/icons-material/PlaylistAddCheckCircle';

function TimelineIcon({ type, ...props }) {
    //STARTED CKECKIN CHAT REPORT ENDED IMAGES SIGNATURE CHECKLIST
    if (type === 'STARTED') return <STARTED {...props} />;
    if (type === 'CHECKIN') return <CHECKIN {...props} />;
    if (type === 'CHAT') return <CHAT {...props} />;
    if (type === 'REPORT') return <REPORT {...props} />;
    if (type === 'ENDED') return <ENDED {...props} />;
    if (type === 'IMAGES') return <IMAGES {...props} />;
    if (type === 'SIGNATURE') return <SIGNATURE {...props} />;
    if (type === 'CHECKLIST') return <CHECKLIST {...props} />;
    return null;
}
export default function OrdersEdit() {
    const { id } = useParams();

    const { data, error, mutate, isFetching } = useFetch(`/order/${id}`);

    if (isFetching) return <LoadingContainer />;
    if (!Boolean(data)) return <NothingContent label="Nenhum dado encontrado" />;

    return (
        <Grid container direction="row" spacing={3}>
            <Grid item xs>
                <OrderForm data={data} mutate={mutate} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <GlobalPaper sx={{ maxHeight: '600px', overflow: 'auto' }}>
                    <Typography variant="h3" sx={{ mb: '1rem', color: '#284db9' }}>
                        Timeline
                    </Typography>
                    <Timeline position="right" sx={{ mt: 0, p: 0 }}>
                        {data.FlowServices?.map(item => (
                            <TimelineItem key={item.id} sx={{ ':before': { padding: 0, flex: 'inherit' } }}>
                                <TimelineSeparator>
                                    <TimelineDot color="primary">
                                        <TimelineIcon color="secondary.main" type={item.type} />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent alignItems="center">
                                    <Typography variant="subtitle2" sx={{ lineHeight: 1 }}>
                                        {item.history}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
                                        {new Date(item.createdAt).toLocaleString('pt-BR')}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </GlobalPaper>
            </Grid>
        </Grid>
    );
}
