import api from '../../../services/api';

export const archivedProfissional = async ({ id }) => {
    const { data } = await api.patch(`/professional/archive/${id}`);
    return data;
};
export const deleteProfissional = async ({ id }) => {
    const { data } = await api.delete(`/professional/${id}`);
    return data;
};

export const listProfissional = async () => {
    const { data } = await api.delete(`/professional`);
    return data;
};
