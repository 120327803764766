import { Button, Chip, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { Cards } from './components/Cards';
import Wallet from '../../assets/icons/wallet.svg';
import Paypal from '../../assets/icons/Paypal.svg';
import InProgress from './components/InProgress';
import Box from '@mui/material/Box';
import LoadingContainer, { NothingContent } from '../../components/LoadingContent';
import { useFetch } from '../../hooks/useFetch';
import CheckIcon from '@mui/icons-material/Check';
import InProfressIcon from '@mui/icons-material/PublishedWithChanges';
import WaitingIcon from '@mui/icons-material/Pending';
import { Graph } from './components/Graph';
import DatePicker from 'react-multi-date-picker';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import gregorian_pt from '../../global/gregorian_pt';
import { useState } from 'react';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import { RatingGraph } from './components/RatingGraph';

export default function Dashboard(props) {
    const [scheduled, setScheduled] = useState();
    const { data, error, mutate, isFetching } = useFetch('/dashboard', {
        params: {
            scheduled,
        },
    });

    if (isFetching) return <LoadingContainer />;
    if (!Boolean(data)) return <NothingContent label="Nenhum dado encontrado" />;

    const CustomSelectDate = ({ openCalendar, value }) => {
        value = value[0]
            ? `${new Date(value[0]).toLocaleDateString()} a ${value[1] ? new Date(value[1]).toLocaleDateString() : ''}`
            : 'Selecione uma data';

        return (
            <Button
                endIcon={<CalendarIcon />}
                style={{ backgroundColor: 'transparent', color: '#284db9' }}
                variant="contained"
                onClick={openCalendar}>
                {value}
            </Button>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="column" spacing={2}>
                {/* <Typography variant="h3" sx={{ mb: '2rem' }}>
                    Dashboard
                </Typography> */}
                <Grid item xs={4} md={3} alignSelf="flex-end">
                    <Stack direction="column" sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 1.2, padding: 2 }}>
                        <DatePicker
                            className="rmdp-mobile"
                            onChange={e => {
                                if (e.length === 2) setScheduled(e);
                            }}
                            range
                            numberOfMonths={2}
                            locale={gregorian_pt}
                            value={scheduled || []}
                            required
                            render={<CustomSelectDate />}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column', md: 'row' }} mb={2} alignItems="stretch" spacing={2}>
                        <Cards name="Aguardando" icon={<WaitingIcon color="danger" />} price={data.countWaiting} />
                        <Cards name="Em Andamento" icon={<InProfressIcon color="danger" />} price={data.countInProgress} />
                        <Cards name="Concluídas" icon={<CheckIcon color="danger" />} price={data.countConcluded} />
                        <RatingGraph rating_medium={data?.ratingMedium} />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} alignItems="stretch" spacing={2}>
                        <Graph data={data.activitiesList} />
                        {/* <Grid item xs={12} md={6}>
                            <Graph data={data.activitiesList} />
                        </Grid>
                        <Grid item xs={12} md={6}></Grid> */}
                    </Stack>
                    {/* <InProgress /> */}
                </Grid>
            </Grid>
        </Box>
    );
}
