import React, { useState } from 'react';
import ContainerGlobal from '../../../components/ContainerGlobal';
import { TextField, Button, Stack, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import GlobalPaper from '../../../components/GlobalPaper';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function FormEditCustomer({ data, mutate }) {
    const { enqueueSnackbar } = useSnackbar();

    const [errs, setError] = useState();
    useHandleErrors(errs);

    const costumerFormScheema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome do Responsável Incompleto').required('Campo obrigatório'),
        email: Yup.string().email('E-mail inválido.'), //.required('Campo obrigatório'),
        customer_name: Yup.string().min(5, 'Nome da empresa').required('Campo obrigatório'),
        document: Yup.string().min(10, 'Documento deve ser maior ou igual a 10').max(14, 'Documento Incompleto'), //.required('Campo obrigatório'),
        cellphone: Yup.string().min(10, 'Número deve ser maior ou igual a 10').max(11, 'Número excedido'), //.required('Campo obrigatório'),
        zipcode: Yup.string().length(8, 'CEP deve conter 8 caracteres').required('Campo obrigatório'),
        address: Yup.string().min(6, 'Endereço Incompleto').required('Campo obrigatório'),
        complement: Yup.string().min(6, 'Complemento Incompleto'), //.required('Campo obrigatório'),
        city: Yup.string().min(4, 'Cidade Incorreta').required('Campo obrigatório'),
        state: Yup.string().length(2, 'Use 2 letras. Ex: RJ').required('Campo obrigatório'),
        details: Yup.string().min(6, 'Número minímo de caracteres: 6').nullable(),
    });

    const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue, setValues } = useFormik({
        initialValues: {
            name: '',
            email: '',
            customer_name: '',
            document: '',
            cellphone: '',
            zicode: '',
            address: '',
            complement: '',
            city: '',
            state: '',
            details: '',
        },
        validationSchema: costumerFormScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await api.put(`customer/${values.id}`, values);
                setSubmitting(false);
                enqueueSnackbar('Cliente alterado', { variant: 'success' });
            } catch (errors) {
                setError(errors);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues({ ...data, name: data.User.name, email: data.User.email }, false);
        }
    }, [data]);

    return (
        <>
            {/* <Stack direction="row" justifyContent="flex-end" sx={{ mb: '1.5rem' }}>
                <Button variant="contained" onClick={() => navigation(`/customers/new`)}>
                Novo Cliente
                </Button>
            </Stack> */}

            <GlobalPaper>
                <Typography variant="h3" sx={{ mb: 3, color: '#284db9' }}>
                    Editando cliente
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Responsável"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="E-mail"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nome da empresa"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.customer_name)}
                            helperText={errors.customer_name}
                            name="customer_name"
                            onChange={handleChange}
                            value={values.customer_name}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Documento"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.document)}
                            helperText={errors.document}
                            name="document"
                            onChange={handleChange}
                            value={values.document}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Telefone"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.cellphone)}
                            helperText={errors.cellphone}
                            name="cellphone"
                            onChange={handleChange}
                            value={values.cellphone}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Endereço"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.address)}
                            helperText={errors.address}
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="CEP"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.zipcode)}
                            helperText={errors.zipcode}
                            name="zipcode"
                            onChange={handleChange}
                            value={values.zipcode}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <TextField
                            fullWidth
                            label="Complemento"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.complement)}
                            helperText={errors.complement}
                            name="complement"
                            onChange={handleChange}
                            value={values.complement}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <TextField
                            fullWidth
                            label="Cidade"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.city)}
                            helperText={errors.city}
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextField
                            fullWidth
                            label="Estado"
                            variant="outlined"
                            sx={{ mb: '2rem' }}
                            error={Boolean(errors.state)}
                            helperText={errors.state}
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                        />
                    </Grid>

                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            label="Detalhes"
                            variant="outlined"
                            sx={{ mb: '3rem' }}
                            error={Boolean(errors.details)}
                            helperText={errors.details}
                            name="details"
                            onChange={handleChange}
                            value={values.details}
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" justifyContent="flex-end">
                    <LoadingButton disabled={isSubmitting} loading={isSubmitting} onClick={() => handleSubmit()} type="submit" variant="contained">
                        Salvar
                    </LoadingButton>
                </Stack>
            </GlobalPaper>
        </>
    );
}
